import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faDesktopAlt, faEllipsisV, faEye, faFile, faHeading, faMobile, faMobileAlt, faMobilePhone, faPen, faPlus, faSchool, faSortNumericAsc, faSortNumericDesc, faSortNumericUp, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { GET, POST } from '../../../controllers/endpoints.controller';
import { getIcon } from '../../../tools/tools';

function NavBarConfig() {
    const PAGE_TITLE = "NavBar";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        getRoles();
    }, []);

    const [roles, setRoles] = useState(null)

    const getRoles = async function () {
        const req = await GET(`/sso/roles`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setRoles(res);
            setSelectedSchoolName(res[0].School.name)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [routes, setRoutes] = useState([
        { path: '/inscripciones', name: 'Inscripciones', icon: "faFileCircleCheck" }
    ])
    const getRoutes = async function () {
        const req = await GET(`/sso/roles`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setRoles(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [selectedSchoolIndex, setSelectedSchoolIndex] = useState(0);
    const [selectedSchoolName, setSelectedSchoolName] = useState("Colegio");
    const handleSelectSchool = (i) => {
        setSelectedSchoolIndex(i)
        setSelectedSchoolName(roles[i].School.name)
    }

    const [editedNavbar, setEditedNavBar] = useState()

    const [isEditing, setIsEditing] = useState(false)
    const handleEdit = () => {
        setIsEditing(true)
    }

    const putNavBar = async () => {
        setIsEditing(false)
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col>

                        <div className='align-center-vertically '>
                            <FontAwesomeIcon icon={faSchool} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedSchoolName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedSchoolName}>
                                    {roles && roles.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectSchool(i)}>{opt.School.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        {!isEditing ? <ActionButton title='Editar' variant='primary' iconFa={faPen} func={handleEdit}/>
                        :<ActionButton title='Guardar cambios' variant='primary' iconFa={faCheck} func={putNavBar}/>}
                    </Col>
                </Row>

                <Card className='cards mt-3'>
                    <div className='slideTable'>
                        <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                            <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                <tr className=' headerContainer'>
                                    <td className='sticky-column' rowSpan="2"></td>
                                    {
                                        roles && roles[selectedSchoolIndex].Roles.map((r, i) =>
                                            <td key={i} rowSpan="2">{r.name}</td>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {routes.map((r, i) => (
                                    <tr key={i} className=''>
                                        <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                                            <Row style={{ borderRadius: '8px 0 0 8px', height: '50px' }} className='tableRows pt-1 pb-1'>
                                                <Col className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'left', width: 150 }}>
                                                        <span className='navbar-icon'><FontAwesomeIcon className='navbar-icon-content' icon={getIcon(r.icon)} /></span>
                                                        <span className='ms-2'>{r.name}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td>
                                        {roles && roles[selectedSchoolIndex].Roles.map(() => (
                                            <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row className='tableRows pt-1 pb-1' style={{ height: '50px' }}>
                                                    <Col className='align-center-vertically-v2'>
                                                        <div style={{ textAlign: 'center', justifyContent: 'center' }} className='align-center-vertically'>
                                                            <FontAwesomeIcon icon={faMobileAlt} style={{ width: '16px' }} /><Form.Check type="switch" defaultChecked={true} disabled={!isEditing} />
                                                        </div>
                                                        <div style={{ textAlign: 'center', justifyContent: 'center' }} className='align-center-vertically'>
                                                            <FontAwesomeIcon icon={faDesktopAlt} style={{ width: '16px' }} /><Form.Check type="switch" defaultChecked={true} disabled={!isEditing} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>
                                        ))}
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default NavBarConfig